@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app {
  width: 100vw;
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 90px;
}

.form-container {
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
}

.form-title {
  font-size: 35px;
  padding-bottom: 1.25rem;
}

.btn-submit {
  min-width: 200px;
  margin-left: auto;
}

.dx-texteditor.dx-editor-outlined {
  border: none !important;
}

.dx-texteditor-input {
  padding: 0 2rem !important;
}

.field-customization {
  border-bottom: 1px solid rgb(158, 158, 158) !important;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-logo {
  width: 50%;
  max-width: 200px;
}

.column-logo {
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 835px) {
  .form-header {
    flex-direction: column-reverse;
  }

  .form-title {
    align-self: center;
    font-size: 1.6rem;
    margin-top: 1.5rem;
  }

  .form-logo {
    width: 100px;
    align-self: flex-end;
  }
}

textarea.materialize-textarea {
  height: 6rem;
}

.field-customization {
  margin-bottom: 8px !important;
}

.text-error {
  color: red;
  margin-top: -8px;
  margin-bottom: 20px;
}

.dx-texteditor.dx-editor-outlined {
  background-color: transparent !important;
}

label {
  font-size: 1rem;
}

[type="checkbox"] + span:not(.lever) {
  line-height: 22px;
}

/* error page */

.err-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(34, 126, 132, 1) 0%,
    rgba(92, 165, 170, 1) 44%,
    rgba(118, 186, 185, 1) 100%
  );
}

.err-content {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  padding: 2rem;
}

.err-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.err-header-text {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
}

.err-header-text-title {
  color: #000;
  font-size: 3rem;
  font-weight: bolder;
}

.err-header-text-desc {
  color: #000;
  font-size: 1.5rem;
  line-height: 0.8;
}
.datepicker-controls .select-month input {
  width: 5em !important;
}
